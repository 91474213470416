//
//
//
//
//

export default {
  async mounted() {

      // Fetch cart data from the server
      const cartRes = await this.$http.get('/cart', {
        uniacid: window.localStorage.getItem('uniacid')
      });

      // Filter out invalid items
      let dataList = cartRes.data.items.data.list.filter(item => !item.is_invalid);

      // Update Vuex store with the cart items count
      this.$store.dispatch('global/updateCartItemsCount', dataList.length);

  }
};
