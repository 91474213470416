//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import productNames from "./Product.json"; 

export default {
  props: ['product'],
  data() {
    return {
      locale: window.localStorage.getItem('locale') || this.$i18n.locale,
      currency: window.localStorage.getItem('currency'),
      uniacid: window.localStorage.getItem('uniacid'),
    };
  },
  created() {
    if (this.locale === 'zh-CN') {
      this.product.goods_name = chineseLanguageLoader(this.product.goods_name, { language: 'zh-CN' });
    } else if (this.locale === 'EN' && this.uniacid && productNames[this.uniacid]) {
      this.product.goods_name = productNames[this.uniacid][this.product.goods_id] || "Unknown Product";
    }
  },
  methods: {
    detail(id) {
      this.$router.push({ path: '/product', query: { id } });
    }
  }
};
